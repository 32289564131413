<template>
	<HokModal :adaptive="false" name="askPrivacyForActiveSourcing">
		<div v-if="user">
			<h3 class="text-center">Datenschutz und Widerrufsrecht</h3>
			<ErrorBox v-if="privacyError">
				Bitte bestätige die Datenschutzerklärung und das Widerrufsrecht
			</ErrorBox>
			<HokCheckbox
				id="privacy_user_register"
				v-model="acceptedPrivacy"
				required
				name="privacy_user_register"
				class="text-sm"
			>
				Hiermit bestätige ich, dass mein Profil, auch ohne Bewerbung, von Arbeitgebern auf hokify
				einsehbar ist und ich von diesen kontaktiert werden möchte.<br />
				<template #more>
					<HokLink class="link-dsgvo underline" to="/privacy#user-active-sourcing" target="_blank">
						Datenschutzerklärung & Widerrufsrecht
					</HokLink>
				</template>
			</HokCheckbox>
			<div class="flex justify-center mt-5">
				<HokButton fullwidth="mobile" class="mb-4" color="main" @click="checkActiveSourcing">
					Aktivieren
				</HokButton>
			</div>
		</div>
		<div v-else class="p-4 pt-8">
			Nicht eingeloggt.
			<nuxt-link to="/pwa/login"> Zum login </nuxt-link>
		</div>
	</HokModal>
</template>

<script lang="ts">
import ErrorBox from '@hokify/shared-components-nuxt3/lib/components/ErrorBox.vue';
import HokCheckbox from '@hokify/shared-components-nuxt3/lib/components/HokCheckbox.vue';

import { PrivacyType } from '@hokify/shared-components-nuxt3/lib/types/privacyType';
import type { IAPILoginUser } from '@hokify/common';
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useUserProfileStore } from '@/stores/user-profile';

export default defineComponent({
	name: 'AskPrivacyForActiveSourcing',
	components: {
		HokCheckbox,
		ErrorBox
	},
	emits: ['done'],
	data() {
		return {
			privacyError: false
		};
	},
	computed: {
		acceptedPrivacy: {
			get() {
				if (!this.privacy || this.privacy.user_active_sourcing === undefined) {
					this.userProfileStore.updatePrivacy({ user_active_sourcing: false });
				}

				return this.privacy?.user_active_sourcing;
			},
			set(value) {
				this.userProfileStore.updatePrivacy({ user_active_sourcing: value });
				if (value) {
					this.userProfileStore.acceptPrivacy(PrivacyType.UserActiveSourcing);
				}
				this.privacyError = false;
			}
		},
		privacy() {
			return this.user?.privacy;
		},
		...mapStores(useUserProfileStore)
	},
	methods: {
		checkActiveSourcing() {
			if (!this.acceptedPrivacy) {
				this.privacyError = true;
			} else {
				this.$emit('done');
				this.$modal.hide('askPrivacyForActiveSourcing');
			}
		}
	},
	props: {
		user: { type: Object as PropType<IAPILoginUser>, default: () => {} }
	}
});
</script>
