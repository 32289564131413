<template>
	<div>
		<HokModal
			:adaptive="!!$isMobile.any"
			:width="$isMobile.any ? '95%' : '350px'"
			:show-close-button="false"
			:click-to-close="false"
			name="askForAppRating"
			transition="scale"
		>
			<div class="flex flex-wrap justify-center text-center">
				<h3 class="mb-5">Wie zufrieden bist du mit hokify?</h3>

				<HokIcon
					v-for="index in 5"
					:key="index"
					:name="selectedStars >= index ? 'icon:star' : 'icon:star-outline'"
					color="main"
					:size="8"
					style="stroke: transparent"
					@click="selectedStars = index"
				></HokIcon>

				<ErrorBox v-if="starRatingError" class="mt-6">
					Bitte wähle mindestens einen Stern.
				</ErrorBox>

				<HokButton fullwidth="always" class="mb-4 mt-6" color="main" @click="userSatisfaction()">
					Senden
				</HokButton>
				<HokButton fullwidth="always" outline color="main" @click="cancelStarRating">
					Später
				</HokButton>
			</div>
		</HokModal>

		<HokModal
			:adaptive="!!$isMobile.any"
			:width="$isMobile.any ? '95%' : '350px'"
			:show-close-button="false"
			:click-to-close="false"
			name="AppRatingGood"
			transition="scale"
		>
			<div class="flex flex-wrap justify-center text-center">
				<h3>
					Bitte bewerte hokify im
					{{ cordova === 'android' ? 'PlayStore' : 'AppStore' }}!
				</h3>
				<HokButton fullwidth="always" class="mb-4" color="main" @click="openExternalStoreLink">
					Jetzt bewerten
				</HokButton>
				<HokButton fullwidth="always" outline color="main" @click="doItLater"> Später </HokButton>
			</div>
		</HokModal>

		<HokModal
			:adaptive="!!$isMobile.any"
			:width="$isMobile.any ? '95%' : '350px'"
			:show-close-button="false"
			:click-to-close="false"
			name="ThankYou"
			transition="scale"
		>
			<div class="flex flex-wrap justify-center text-center">
				<h3>Vielen Dank für dein Feedback</h3>
				<HokButton fullwidth="always" class="mb-4" color="main" @click="$modal.hide('ThankYou')">
					Schließen
				</HokButton>
			</div>
		</HokModal>

		<HokModal
			:adaptive="!!$isMobile.any"
			:width="$isMobile.any ? '95%' : '350px'"
			:show-close-button="false"
			:click-to-close="false"
			name="AppRatingBad"
			transition="scale"
		>
			<div class="flex flex-wrap justify-center text-center">
				<h3>Wir würden uns über dein Feedback freuen!</h3>
				<p>Du kannst uns gerne deine Anliegen übermitteln, damit wir hokify für dich verbessern.</p>
				<HokButton fullwidth="always" class="mb-4" color="main" @click="openFeedbackForm">
					Feedback geben
				</HokButton>
				<HokButton fullwidth="always" outline color="main" @click="doItLater"> Später </HokButton>
			</div>
		</HokModal>
		<template v-if="displayFeedbackbutton">
			<span class="text-color-main cursor-pointer" @click="openAppRating">Gib uns Feedback</span>
		</template>
	</div>
</template>

<script lang="ts">
import { nativeLinkOpener } from '@hokify/shared-components-nuxt3/lib/helpers/nativeLinkOpener';
import ErrorBox from '@hokify/shared-components-nuxt3/lib/components/ErrorBox.vue';
import { lsTest } from '@hokify/shared-components-nuxt3/lib/helpers/localstorage';
import { defineComponent } from 'vue';

export default defineComponent({
	layout: 'default',
	components: {
		ErrorBox
	},
	data() {
		const cordova: string | null = process.env.cordova || null;

		return {
			cordova,
			starRatingError: false,
			selectedStars: 0
		};
	},
	methods: {
		openAppRating() {
			this.$trackUserEvent?.('app_rating_view', {});
			this.$modal.show('askForAppRating');
		},
		userSatisfaction() {
			if (this.selectedStars === 0) {
				this.starRatingError = true;
				return;
			}
			this.$trackUserEvent?.('app_rating_send', { amountStars: this.selectedStars });
			this.$modal.hide('askForAppRating');
			if (this.cordova)
				this.$modal.show(this.selectedStars === 5 ? 'AppRatingGood' : 'AppRatingBad');
			else this.$modal.show(this.selectedStars === 5 ? 'ThankYou' : 'AppRatingBad');
		},
		cancelStarRating() {
			this.$modal.hide('askForAppRating');
			this.$trackUserEvent?.('app_rating_cancel', {});
			if (lsTest()) {
				localStorage.setItem('AppRatingDoItLater', new Date().toString());
				localStorage.removeItem('AppRatingDone');
			}
		},
		openExternalStoreLink() {
			this.$trackUserEvent?.('app_rating_open_store', {});
			this.$modal.hide('AppRatingGood');
			if (lsTest()) {
				localStorage.setItem('AppRatingDone', 'true');
			}
			const storeLink =
				this.cordova === 'android'
					? 'https://play.google.com/store/apps/details?id=com.yfabrik.hocknfinder'
					: 'https://itunes.apple.com/app/id1069568033?action=write-review';

			window.open(storeLink, '_blank');
		},
		openFeedbackForm($event) {
			this.$trackUserEvent?.('app_rating_send_feedback_now', {});
			this.$modal.hide('AppRatingBad');
			if (lsTest()) {
				localStorage.setItem('AppRatingDone', 'true');
			}

			nativeLinkOpener(
				'https://hokify.at/feedback',
				this.cordova ? '_blank' : '',
				$event,
				'location=yes'
			);
		},
		doItLater() {
			if (this.selectedStars === 5) {
				this.$trackUserEvent?.('app_rating_open_store_later', {});
			} else {
				this.$trackUserEvent?.('app_rating_send_feedback_later', {});
			}
			if (lsTest()) {
				localStorage.setItem(
					'AppRatingDoItLater',
					// for debugging: new Date(new Date().setDate(new Date().getDate() - 8))
					new Date().toString()
				);
				localStorage.removeItem('AppRatingDone');
			}
			this.$modal.hide(this.selectedStars === 5 ? 'AppRatingGood' : 'AppRatingBad');
		}
	},
	props: {
		displayFeedbackbutton: { type: Boolean, default: false }
	}
});
</script>
